import { notification,Spin } from "antd"
import { saveAuthToken } from "app/authentication"
import { useAuthUser } from "app/authentication/useAuthUser"
import useAxios from "axios-hooks"
import React from "react"
import { useNavigate } from "react-router-dom"

import { LoginFormType } from "./login-form.types"
import { LoginFormView } from "./login-form.view"

export function LoginForm(): React.ReactElement {
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  const { user, loading } = useAuthUser()

  if (user != null) {
    navigate(-1)
  }

  const [loginState, login] = useAxios({
    url: "/token",
    method: "POST"
  }, {
    manual: true
  })

  async function handlePressSubmit(values: LoginFormType): Promise<void> {
    try {
      const response = await login({
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: {
          username: values.username,
          password: values.password
        }
      })
      const accessToken = `${response.data.token_type} ${response.data.access_token}`
      saveAuthToken(accessToken)
      navigate(-1)
    } catch (error) {
      api.error({
        message: "Неверный логин или пароль"
      })
    }
  }

  if (loading) {
    return <Spin />
  }


  return (
    <div>
      {contextHolder}
      <LoginFormView onSubmit={handlePressSubmit} isLoading={loginState.loading} />
    </div>
  )
}