import { RoutePathsEnum } from "app/routing/enum-routes"
import React from "react"
import { useRoutes } from "react-router-dom"

import { LoginPage } from "../containers/login"

export const AuthRoutes: React.FC = () =>
  useRoutes([
    { path: RoutePathsEnum.Login, element: <LoginPage /> },
    { path: "*", element: <LoginPage /> },
  ])
