import AuthPage from "app/pages/auth"
import React from "react"
import { createBrowserRouter } from "react-router-dom"

import { NoMatch } from "../components"
import { LayoutPage } from "../pages/layout"
import { RoutePathsEnum } from "./enum-routes"

export const appRouter = createBrowserRouter([
  {
    path: `/${RoutePathsEnum.Auth}/*`,
    element: <AuthPage />,
    errorElement: <NoMatch />,
  },
  {
    path: "*",
    element: <LayoutPage />,
    errorElement: <NoMatch />,
  },
])
