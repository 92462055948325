import { RoutingIndicator } from "app/components/routing-indicator"
import React, { Suspense } from "react"
import { Outlet } from "react-router-dom"

import { AuthRoutes } from "./routing/routes"

function AuthPage(): React.ReactElement {
  return (
    <div>
      <Suspense fallback={<RoutingIndicator />}>
        <AuthRoutes />
        <Outlet />
      </Suspense>
    </div>
  )
}

export default AuthPage
