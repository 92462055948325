import React from "react"
import { Img, ImgProps } from "react-image"

export type ImageSourceType = string | null

interface ImageViewProps extends Omit<ImgProps, "src" | "alt"> {
  src: ImageSourceType
  local?: boolean
  defaultImageSrc?: string
  borderRadius?: number
}

function buildImageURL(imagePath: string, local: boolean): string {
  if (local) {
    return imagePath
  }
  return `${process.env.BACKEND_URL}${imagePath}`
}

export function ImageView(props: ImageViewProps): React.ReactElement {
  const {
    src,
    local = false,
    ...rest
  } = props
  return (
    <Img
      {...rest}
      src={buildImageURL(src ?? "", local)}
      alt="Best image forever"
      style={{
        objectFit: "cover",
      }}
    />
  )
}
