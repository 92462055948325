import { Flex } from "antd"
import React, { useState } from "react"

import downIcon from "../../../../icons/down.svg"
import folderIcon from "../../../../icons/folder.svg"
import pencilIcon from "../../../../icons/pencil.svg"
import rightIcon from "../../../../icons/right.svg"
import { Article, Author } from "../../../../interfaces/article.types"
import css from "./author-list.module.scss"

interface AuthorListViewProps {
  author: Author
  articles: Article[]

  selectedArticleId: string
  onClickArticle: (id: string) => void
}

export function AuthorListView({
  author, articles, selectedArticleId, onClickArticle
}: AuthorListViewProps): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  return (
    <div className={css.articleListTitleContainer}>
      <Flex
        onClick={(): void => setIsOpen((prev) => !prev)}
        justify="space-between"
        className={css.articleAuthorContainer}
      >
        <div>
          <img src={folderIcon} className={css.articleFolderIcon} alt="" srcSet="" />
          <span style={{ color: isOpen ? "#0061FF" : undefined }}>{author}</span>
        </div>
        <div><img src={isOpen ? downIcon : rightIcon} alt="" srcSet="" /></div>
      </Flex>
      {isOpen && articles.map((article, index) => (
        <div key={index} className={css.articleItemContainer}>
          <Flex align="center" justify="space-between" onClick={(): void => onClickArticle(article.id)}>
            <div style={{
              ...(selectedArticleId == article.id ? {
                color: "#0061FF",
                textDecoration: "underline" 
              } : {})
            }}>{article.title}</div>
            <div><img src={pencilIcon} alt="" srcSet="" /></div>
          </Flex>
        </div>
      ))}
    </div>
  )
}