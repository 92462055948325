import React from "react"

import css from "./index.module.scss"

export function InfiniteProgressBar(): React.ReactElement {
  return (
    <div className={css.progressBar} role="progressbar">
      <div className={css.progressBarValue} />
    </div>
  )
}
