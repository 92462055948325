import { Spin } from "antd"
import { ResponseApi } from "api/response.type"
import useAxios from "axios-hooks"
import React from "react"

import { Article, Author } from "../../../../interfaces/article.types"
import { AuthorListView } from "./author-list.view"

interface AuthorListProps {
  author: Author
  selectedArticleId: string | null
  onClickArticle: (id: string) => void
}

export function AuthorList({
  author,
  selectedArticleId,
  onClickArticle
}: AuthorListProps): React.ReactElement {
  const [{ data, loading }] = useAxios<ResponseApi<{ article_comments: Article[] }>>({
    url: "/authors/get_articles_by_author",
    params: {
      author_name: author,
      size: 10,
      get_from: 0
    }
  })

  if (loading) {
    return <Spin />
  }

  return (
    <AuthorListView
      author={author}
      articles={data?.result.article_comments ?? []}
      selectedArticleId={selectedArticleId ?? ''}
      onClickArticle={onClickArticle}
    />
  )
}