import { Button,Form, Input } from "antd"
import React from "react"

import { LoginFormType } from "./login-form.types"

interface LoginFormProps {
  isLoading: boolean
  onSubmit: (values: LoginFormType) => void
}

export function LoginFormView({
  isLoading,
  onSubmit
}: LoginFormProps): React.ReactElement {
  return (
    <div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onSubmit}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item<LoginFormType>
          label="Логин"
          name="username"
          rules={[{ required: true, message: "Пожалуйста, укажите логин" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<LoginFormType>
          label="Пароль"
          name="password"
          rules={[{ required: true, message: "Пожалуйста, укажите пароль" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" loading={isLoading} disabled={isLoading}>
            Войти
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}