import { LoginOutlined } from "@ant-design/icons"
import { Button, Spin } from "antd"
import { saveAuthToken } from "app/authentication"
import { useAuthUser } from "app/authentication/useAuthUser"
import { RoutePathsEnum } from "app/routing/enum-routes"
import React from "react"
import { useNavigate } from "react-router-dom"

import { ProfileMenuView } from "./profile-manu.view"

export function ProfileMenu(): React.ReactElement {
  const navigate = useNavigate()
  const { user, loading } = useAuthUser()

  function handleClickLoginButton(): void {
    navigate({
      pathname: `/${RoutePathsEnum.Auth}`
    })
  }

  function handleClickLogoutButton(): void {
    saveAuthToken("")
    window.location.href = "/"
  }

  if (loading) {
    return <Spin />
  }

  if (user == null) {
    return <Button type="primary" icon={<LoginOutlined />} onClick={handleClickLoginButton}>Вход</Button>
  }
  return <ProfileMenuView name={user.username} onLogout={handleClickLogoutButton} avatarUrl={null} />
}