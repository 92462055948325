import { ReadOutlined } from "@ant-design/icons"
import { Button, Card, Col, Divider, Row, Typography } from "antd"
// import { CommentsList } from "app/components/comments/comments-list/comments-list.container"
import React from "react"

import { Article } from "../../../../interfaces/article.types"

const { Title, Paragraph } = Typography

interface ArticleInfoCardViewProps {
  article: Article
  onClickReadButton: () => void
}

export function ArticleInfoCardView({
  article,
  onClickReadButton
}: ArticleInfoCardViewProps): React.ReactElement {
  return (
    <Card>
      <Row justify="space-between" align="middle">
        <Col>
          <Title level={2}>{article.title}</Title>
        </Col>
        <Col>
          <Button type="primary" icon={<ReadOutlined />} size="large" onClick={onClickReadButton}>Читать</Button>
        </Col>
      </Row>
      <Divider />
      <Title level={3}>{article.author}</Title>
      <div>
        <Paragraph>{article.description}</Paragraph>
      </div>
      {/* <div
        style={{
          marginLeft: 45,
          marginRight: 45
        }}
      >
        <CommentsList articleId={article.id} title="последние комментарии" />
      </div> */}
    </Card>
  )
}
