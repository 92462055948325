import { Layout,Space } from "antd"
import { Content, Footer } from "antd/es/layout/layout"
import React from "react"
import { Outlet, ScrollRestoration, useNavigation } from "react-router-dom"

import { RoutingIndicator } from "../../components/routing-indicator"
import { FooterView } from "./components/footer/footer.view"
import { HeaderContainer } from "./components/header/header.container"
import { footerStyle } from "./index.style"
import { LayoutRoutes } from "./routing/routes"

export const LayoutPage: React.FC = () => {
  const navigation = useNavigation()

  return (
    <Space direction="vertical" size={[0, 48]} style={{ width: '100%', }}>
      <Layout style={{ minHeight: "100vh", overflow: "hidden", backgroundColor: "#F6F6F6" }}>
        <HeaderContainer />
        <Layout>
          <Content style={{ paddingTop: 33 }}>
            <div>
              {/* <Navigate to={`/${PagePathsEnum.Auth}`} /> */}
              <LayoutRoutes />
              <Outlet />
              <ScrollRestoration />
              {navigation.state === "loading" && <RoutingIndicator />}
            </div>
          </Content>
        </Layout>
        <Footer style={footerStyle}><FooterView /></Footer>
      </Layout>
    </Space>
  )
}
