/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { CheckCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Badge, Button, Flex, Space, Typography } from "antd"
import React from "react"

import { Comment } from "../../../interfaces/comment.types"

const { Paragraph } = Typography

interface CommentCeilViewProps {
  comment: Comment
  onChangeComment: () => void
  onDeleteComment: () => void
  badgeNumber?: number
  isDeleted?: boolean
  onClickBadge?: () => void
  isAdmin?: boolean
}

export function CommentCeilView(props: CommentCeilViewProps): React.ReactElement {
  const { comment, badgeNumber, onChangeComment, onDeleteComment, onClickBadge, isDeleted, isAdmin = false } = props
  return (
    <Flex justify="space-between">
      <div>
        <Paragraph>
          <span onClick={onClickBadge} style={{ cursor: "pointer" }}><Badge style={{ marginRight: "10px", backgroundColor: "#0061FF" }} count={badgeNumber} showZero /></span>
          {comment.content}
        </Paragraph>
      </div>
      {isAdmin ? (
        <Space style={{
          marginLeft: 10
        }}>
          {
            onChangeComment == null ? (
              <></>
            ) : (
              <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={onChangeComment} />
            )
          }
          {
            onDeleteComment == null ? (
              <></>
            ) : (
              <Button type="primary" shape="circle" danger icon={isDeleted ? <CheckCircleOutlined /> : <DeleteOutlined />} disabled={isDeleted} onClick={onDeleteComment}/>
            )
          }
        </Space>
      ) : <></>}
    </Flex>
  )
}
