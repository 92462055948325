export const headerStyle: React.CSSProperties = {
  backgroundColor: "#fff",
}

export const contentStyle: React.CSSProperties = {
  textAlign: "center",
  minHeight: 120,
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#108ee9",
}

export const siderStyle: React.CSSProperties = {
  textAlign: "center",
  lineHeight: "120px",
  color: "#fff",
  backgroundColor: "#3ba0e9",
}

export const footerStyle: React.CSSProperties = {
  backgroundColor: "transparent",
  marginTop: "20px",
  borderTop: "1px solid #C6CCD8",
  color: "#000",
  background: "#000",
}