import { Empty } from "antd"
import React, { useState } from "react"

import { ContentBox } from "../layout/components/content-box/content-box.view"
import { ArticleInfoCard } from "./components/article-info-card/article-info-card.container"
import { ArticleList } from "./components/article-list/article-list.container"

function MainPage(): React.ReactElement {
  const [selectedArticleId, setSelectedArticle] = useState<string | null>(null)

  return (
    <ContentBox
      left={<ArticleList selectedArticleId={selectedArticleId} onClickArticle={setSelectedArticle} />}
      right={selectedArticleId == null ? (<Empty description={"Выберите текст для вывода подробной информации"} />) : <ArticleInfoCard articleId={selectedArticleId} />}
    />
  )
}

export default MainPage
