import { Space } from "antd"
import React from "react"

import css from "./article-list.module.scss"
import { AuthorList } from "./author-list.container"

interface ArticleListViewProps {
  authors: string[]
  selectedArticleId: string | null
  onClickArticle: (id: string) => void
}

export function ArticleListView({
  authors,
  selectedArticleId,
  onClickArticle
}: ArticleListViewProps): React.ReactElement {
  return (
    <Space direction="vertical" className={css.articleListContainer}>
      {authors.map((item, index) => <AuthorList key={index} selectedArticleId={selectedArticleId} author={item} onClickArticle={onClickArticle} />)}
    </Space>
  )
}