import { Spin } from "antd"
import { ResponseApi } from "api/response.type"
import { RoutePathsEnum } from "app/routing/enum-routes"
import useAxios from "axios-hooks"
import React from "react"
import { useNavigate } from "react-router-dom"

import { Article } from "../../../../interfaces/article.types"
import { ArticleInfoCardView } from "./article-info-card.view"

interface ArticleInfoCardProps {
  articleId: string
}

export function ArticleInfoCard({
  articleId
}: ArticleInfoCardProps): React.ReactElement {
  const navigate = useNavigate()
  const [{ data, loading }] = useAxios<ResponseApi<{ article: Article }>>({
    url: "/article/get_article_by_id",
    params: {
      article_id: articleId
    }
  })

  if (loading)
    return <Spin />
  
  if (data == null)
    return <></>

  return <ArticleInfoCardView article={{ ...data.result.article, id: articleId }} onClickReadButton={(): void => navigate(`${RoutePathsEnum.Article}/${articleId}`)} />
}
