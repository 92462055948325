import { ResponseApi } from "api/response.type"
import { ArticleRow } from "app/interfaces/article.types"
import { Comment } from "app/interfaces/comment.types"
import { PAGE_SIZE } from "app/pages/text-reader.constant"
import { RoutePathsEnum } from "app/routing/enum-routes"
import useAxios from "axios-hooks"
import _ from "lodash"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

import { SearchContainerView } from "./search.view"

export function SearchContainer(): React.ReactElement {
  const navigate = useNavigate()
  const [queryText, setQueryText] = useState<string | undefined>(undefined)
  const [articleRowsState] = useAxios<ResponseApi<{ article_rows: ArticleRow[] }>>({
    url: "/article/search_rows_in_articles",
    params: {
      query: queryText,
      count_match_rows: 10,
    }
  })

  const [commentsState] = useAxios<ResponseApi<{ comments: Comment[] }>>({
    url: "/comment/search_comments",
    params: {
      query: queryText,
    }
  })

  function handleSearch(value: string): void {
    setQueryText(value ? value : undefined)
  }

  function handlePressProject(value: string): void {
    setQueryText(undefined)
    const [typeItem, id] = value.split("-|-")
    if (typeItem == "p") {
      const row = articleRowsState.data?.result.article_rows.find((row) => (row.row_id.toString() == id))
      if (row == null) {
        console.info({
          message: "Не удалось найти произведение"
        })
      }
      const rowNumber = row?.row_number_in_article ?? 0
      const currentPage = rowNumber - PAGE_SIZE <= 0 ? 1 : _.floor(rowNumber / PAGE_SIZE) + 1
      navigate({
        pathname: `/${RoutePathsEnum.Article}/${row?.article_id}`,
        search: `?page=${currentPage}`,
      })
    }
    if (typeItem == "c") {
      const comment = commentsState.data?.result.comments.find((comment) => (comment.id == id))
      if (comment == null) {
        console.info({
          message: "Не удалось найти комментарий"
        })
      }
      navigate(`/${RoutePathsEnum.Article}/${comment?.article_id}/comment/${id}`)
    }
  }


  return (
    <SearchContainerView
      value={queryText}
      onChangeValue={handlePressProject}
      onSearchValue={handleSearch}
      loading={articleRowsState.loading || commentsState.loading}
      articleRows={_.slice(articleRowsState.data?.result.article_rows, 0, 15) ?? []}
      comments={_.slice(commentsState.data?.result.comments, 0, 15) ?? []}
    />
  )
}
