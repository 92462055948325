import ArticlePage from "app/pages/article"
import CommentEditPage from "app/pages/comment-edit"
import CommentPage from "app/pages/comment-view"
import MainPage from "app/pages/main"
import React from "react"
import { useRoutes } from "react-router-dom"

import { NoMatch } from "../../../components"
import { RoutePathsEnum } from "../../../routing/enum-routes"

export const LayoutRoutes: React.FC = () =>
  useRoutes([
    {
      path: RoutePathsEnum.Root,
      element: <MainPage />,
      errorElement: <div>Error elemtnt from router</div>,
    },
    {
      path: `${RoutePathsEnum.Article}/:articleId`,
      element: <ArticlePage />,
      errorElement: <div>Error elemtnt from router</div>,
    },
    {
      path: `${RoutePathsEnum.Article}/:articleId/comment/:commentId`,
      element: <CommentPage />,
      errorElement: <div>Error elemtnt from router</div>,
    },
    {
      path: `${RoutePathsEnum.Article}/:articleId/comment/:commentId/edit`,
      element: <CommentEditPage />,
      errorElement: <div>Error elemtnt from router</div>,
    },
    { path: "*", element: <NoMatch /> },
  ])
