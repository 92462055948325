import { Card, Divider, Empty } from "antd"
import React from "react"

import { Comment } from "../../../interfaces/comment.types"
import { CommentCeil } from "../comment-ceil/comment-ceil.container"


interface CommentsListView {
  title: string
  comments: Comment[]
}

export function CommentsListView({ title, comments }: CommentsListView): React.ReactElement {
  return (
    <Card>
      <Divider orientation="left">{title}</Divider>
      {!comments.length && (
        <Empty description="Пока нет комментариев" />
      )}
      {comments.map((item, index) => (
        <div key={index}>
          <CommentCeil commentId={item.comment_id} badgeNumber={index + 1} />
          <Divider dashed />
        </div>
      ))}
    </Card>    
  )
}