import { Empty } from "antd"
import { useAuthUser } from "app/authentication/useAuthUser"
import { CommentsList } from "app/components/comments/comments-list/comments-list.container"
import React, { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"

import { ContentBox } from "../layout/components/content-box/content-box.view"
import { CommentsAddEditor } from "./components/comments-add-editor/comments-editor.container"
import { TextReader } from "./components/text-reader/text-reader.container"
import { SelectedWord } from "./components/types"

function ArticlePage(): React.ReactElement {
  const param = useParams<{ articleId: string }>()
  const { user } = useAuthUser()
  const [searchParams, setSearchParams] = useSearchParams()
  const articleId = param.articleId
  const wordIndex = searchParams.get("wordIndex") ?? -1
  const rowNumber = searchParams.get("rowNumber") ?? -1
  const [selectedWordInfo, setSelectedWordInfo] = useState<SelectedWord>({
    rowNumber: +rowNumber,
    wordIndex: +wordIndex,
    status: "WAITING"
  })
  const textPage = +(searchParams.get("page") ?? 1)


  useEffect(() => {
    if (selectedWordInfo.status == "CREATED") {
      setSelectedWordInfo(prev => ({
        ...prev,
        status: "WAITING"
      }))
    }
  }, [user, selectedWordInfo.status])

  function handlePressPage(pageNumber: number): void {
    searchParams.set("page", pageNumber.toString())
    setSearchParams(searchParams)
  }

  function handlePressWord(wordIndex: number, rowNumber: number): void {
    if (user == null)
      return

    setSelectedWordInfo(prev => ({
      ...prev,
      wordIndex,
      rowNumber,
    }))
  }

  if (articleId == null) {
    return <Empty description="Произведение не найдено" />
  }

  return (
    <ContentBox
      left={
        <TextReader
          articleId={articleId}
          selectedWordInfo={selectedWordInfo}
          onClickWord={handlePressWord}
          currentPage={textPage}
          setCurrentPage={handlePressPage}
        />
      }
      right={
        selectedWordInfo.rowNumber == -1 ? (
          <CommentsList
            title="комментарии"
            articleId={articleId}
            current_page={textPage}
            doRefetch={selectedWordInfo.status == "CREATED"}
          />
        ) : (
          <CommentsAddEditor
            selectedWordInfo={selectedWordInfo}
            articleId={articleId}
            onClickCancel={(): void => setSelectedWordInfo({
              status: "WAITING",
              wordIndex: -1,
              rowNumber: -1,
            })}
            onClickSave={(): void => setSelectedWordInfo({
              status: "CREATED",
              wordIndex: -1,
              rowNumber: -1,
            })}
          />
        )}
    />
  )
}

export default ArticlePage
