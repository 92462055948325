import { Card, Col, Row } from "antd"
import React from "react"
import { Link, useNavigate } from "react-router-dom"

import { LoginForm } from "./components/login-form/login-form.container"
import css from "./index.module.scss"

export function LoginPage(): React.ReactElement {
  const navigate = useNavigate()
  return (
    <Row className={css.container} align="middle">
      <Col span={6} offset={9}>
        <Card title="Вход в панель администратора" extra={<Link to="" onClick={(): void => navigate(-1)}>Назад</Link>}>
          <LoginForm />
        </Card>
      </Col>
    </Row>
  )
}