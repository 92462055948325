import { Col, Divider, Flex, Row } from "antd"
import { ImageView } from "app/components/image"
import { SearchContainer } from "app/components/search/search.container"
import React from "react"
import { Link } from "react-router-dom"

import { ProfileMenu } from "../profile-menu/profile-menu.container"
import logoImg from "./../../../../../images/logo-black.png"
import logoIonImg from "./../../../../../images/logo-ion-black.png"
import logoTitleImg from "./../../../../../images/title-black.png"
import css from "./header.module.scss"


const heightGroup = 30

export function HeaderView(): React.ReactElement {
  return (
    <Row className={css.headerContainer}>
      
      <Col span={6}>
        <Link to="/">
          <Flex align="center">
            <ImageView
              src={logoImg}
              local
              height={heightGroup}
              borderRadius={0}
            />
            <Divider
              type="vertical"
              style={{
                fontSize: heightGroup + 10
              }}
              className={css.headerDivider}
            />
            <ImageView
              src={logoTitleImg}
              local
              height={heightGroup}
              borderRadius={0}
            />
            <Divider
              type="vertical"
              style={{
                fontSize: heightGroup + 10
              }}
              className={css.headerDivider}
            />
            <ImageView
              src={logoIonImg}
              local
              height={heightGroup}
              borderRadius={0}
            />
          </Flex>
        </Link>
      </Col>
      <Col span={14}><Flex justify="end"><SearchContainer /></Flex></Col>
      <Col offset={1} span={3}><ProfileMenu /></Col>
    </Row>
  )
}
