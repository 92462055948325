import { Spin } from "antd"
import { ResponseApi } from "api/response.type"
import { useAuthUser } from "app/authentication/useAuthUser"
import { PAGE_SIZE } from "app/pages/text-reader.constant"
import useAxios from "axios-hooks"
import _ from "lodash"
import React from "react"
import { useNavigate } from "react-router-dom"

import { Comment } from "../../../interfaces/comment.types"
import { CommentCeilView } from "./comment-ceil.view"

interface CommentCeilProps {
  commentId: string
  badgeNumber?: number
}

export function CommentCeil(props: CommentCeilProps): React.ReactElement {
  const { commentId, badgeNumber } = props
  const { user, loading } = useAuthUser()

  const navigate = useNavigate()

  const [comment_state] = useAxios<ResponseApi<{ article_comment: Comment }>>({
    url: "/comment/get_comment_by_id",
    params: {
      comment_id: commentId
    }
  })

  const [delete_state, executeDelete] = useAxios<ResponseApi<string>>({
    url: `/comment/delete_comment?comment_id=${commentId}`,
    method: "POST",
  }, { manual: true })

  async function handleDeleteButton(): Promise<void> {
    await executeDelete()
  }

  if (comment_state.loading || comment_state.data == null || loading) {
    return <Spin />
  }

  const rowNumber = comment_state.data.result.article_comment.row_number_in_article
  const currentPage = rowNumber - PAGE_SIZE <= 0 ? 1 : _.floor(rowNumber / PAGE_SIZE) + 1

  function handleClickChangeButton(): void {
    navigate(
      {
        pathname: `/article/${comment_state.data?.result.article_comment.article_id}/comment/${commentId}/edit`,
        search: `?page=${currentPage}`,
      }
    )
  }

  function handleClickBadge(): void {
    navigate(
      {
        pathname: `/article/${comment_state.data?.result.article_comment.article_id}/comment/${commentId}`,
        search: `?page=${currentPage}`,
      }
    )
  }

  return (
    <CommentCeilView
      comment={comment_state.data.result.article_comment}
      onChangeComment={handleClickChangeButton}
      onDeleteComment={handleDeleteButton}
      onClickBadge={handleClickBadge}
      isDeleted={delete_state.data?.status === "ok"}
      badgeNumber={badgeNumber}
      isAdmin={user != null}
    />
  )
}
