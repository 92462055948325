import { Col, Divider, Flex, Row, Typography } from "antd"
import { ImageView } from "app/components/image"
import React from "react"

import logoIonImg from "./../../../../../images/logo-ion-white.png"
import logoImg from "./../../../../../images/logo-white.png"
import logoTitleImg from "./../../../../../images/title-white.png"
import css from "./footer.module.scss"

const { Text } = Typography

const heightGroup = 33

export function FooterView(): React.ReactElement {
  return (
    <Row align="middle" justify="center" className={css.footerContainer}>
      <Col span={9} style={{ textAlign: "center" }}>
        <Text className={css.footerText}>
          Создание новой модели цифрового издания классических текстов
        </Text>
        <br />
        <Text className={css.footerTextHelp}>
          (на примере античной драмы)
        </Text>
        <Flex align="center" justify="center" className={css.footerLogoContainer}>
          <ImageView
            src={logoImg}
            local
            height={heightGroup}
            borderRadius={0}
          />
          <Divider
            type="vertical"
            style={{
              fontSize: heightGroup + 10
            }}
            className={css.footerDivider}
          />
          <ImageView
            src={logoTitleImg}
            local
            height={heightGroup}
            borderRadius={0}
          />
          <Divider
            type="vertical"
            style={{
              fontSize: heightGroup + 10
            }}
            className={css.footerDivider}
          />
          <ImageView
            src={logoIonImg}
            local
            height={heightGroup}
            borderRadius={0}
          />
        </Flex>
      </Col>
    </Row>
  )
}