import "./app/styles/main.scss"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import Axios from "axios"
import { configure } from "axios-hooks"
import {
  LRUCache
} from "lru-cache"
import React from "react"
import ReactDOM from "react-dom/client"
import {
  RouterProvider,
} from "react-router-dom"

import { appRouter } from "./app/routing/routes"
import reportWebVitals from "./reportWebVitals"

const BASE_API_URL = "http://83.143.66.62:27361"

const axios = Axios.create({
  baseURL: BASE_API_URL,
})
const cache = new LRUCache({ max: 1 })

configure({ axios, cache })

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)
root.render(
  <React.StrictMode>
    <RouterProvider router={appRouter}/>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
