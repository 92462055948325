import { Spin } from "antd"
import { ResponseApi } from "api/response.type"
import useAxios from "axios-hooks"
import React from "react"

import { Author } from "../../../../interfaces/article.types"
import { ArticleListView } from "./article-list.view"

interface ArticleListProps {
  selectedArticleId: string | null
  onClickArticle: (id: string) => void
}

export function ArticleList({
  selectedArticleId,
  onClickArticle
}: ArticleListProps): React.ReactElement {
  const [{ data, loading }] = useAxios<ResponseApi<{ authors_list: Author[] }>>({
    url: "/authors/get_all_articles_authors",
  })

  if (loading) {
    return <Spin />
  }
  return <ArticleListView selectedArticleId={selectedArticleId} authors={data?.result.authors_list ?? []} onClickArticle={onClickArticle} />
}