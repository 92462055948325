import { Card, Empty, Spin } from "antd"
import { ResponseApi } from "api/response.type"
import { useAuthUser } from "app/authentication/useAuthUser"
import useAxios from "axios-hooks"
import _ from "lodash"
import React from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { Comment } from "../../interfaces/comment.types"
import { ContentBox } from "../layout/components/content-box/content-box.view"
import { PAGE_SIZE } from "../text-reader.constant"
import { CommentsUpdateEditor } from "./components/comments-update-editor/comments-editor.container"
import { TextReader } from "./components/text-reader/text-reader.container"

function CommentEditPage(): React.ReactElement {
  const { user, loading } = useAuthUser()
  const param = useParams<{ articleId: string, commentId: string }>()
  const navigate = useNavigate()
  const articleId = param.articleId
  const commentId = param.commentId ?? ""
  
  const [comment_state] = useAxios<ResponseApi<{ article_comment: Comment }>>({
    url: "/comment/get_comment_by_id",
    params: {
      comment_id: commentId
    }
  })

  function handlePressPage(pageNumber: number): void {
    navigate({
      pathname: `/article/${articleId}`,
      search: `?page=${pageNumber}`,
    })
  }

  if (articleId == null) {
    return <Empty description="Произведение не найдено" />
  }

  if (comment_state.loading || comment_state.data == null || loading)
    return <Spin />

  const rowNumber = comment_state.data.result.article_comment.row_number_in_article
  const currentPage = rowNumber - PAGE_SIZE <= 0 ? 1 : _.floor(rowNumber / PAGE_SIZE) + 1

  if (user == null) {
    navigate({
      pathname: `/article/${articleId}/comment/${commentId}`,
      search: `?page=${currentPage}`,
    })
  }

  return (
    <ContentBox
      left={
        <TextReader
          articleId={articleId}
          commentId={commentId}
          currentPage={currentPage}
          setCurrentPage={handlePressPage}
        />
      }
      right={
        <Card title="Изменение комментария" extra={<Link to={`/article/${articleId}?page=${currentPage}`}>Все комментарии</Link>}>
          <CommentsUpdateEditor
            commentId={commentId}
            onClickCancel={(): void => handlePressPage(currentPage)}
            onClickSave={(): void => handlePressPage(currentPage)}
          />
        </Card>
      }
    />
  )
}

export default CommentEditPage
