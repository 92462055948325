import { Button, Card, Col, Row } from "antd"
import { ContentState, convertToRaw, EditorState, RawDraftContentState } from "draft-js"
import React from "react"
import { Editor } from "react-draft-wysiwyg"

import { toolbarOptions } from "./comments-editor.options"


interface CommentsReaderView {
  initialText: string
  isLoading: boolean
  onClickSave: (content: string) => void
  onClickClose: () => void
}

export function CommentsEditorView({
  initialText,
  isLoading,
  onClickSave,
  onClickClose
}: CommentsReaderView): React.ReactElement {
  const [editorState, onEditorState] = React.useState<EditorState>(
    () => EditorState.createWithContent(ContentState.createFromText(initialText))
  )

  function getTextFromRaw(raw: RawDraftContentState): string {
    return raw.blocks.map(block => (!block.text.trim() && "\n") || block.text).join("\n")
  }

  return (
    <Card style={{ height: "100%" }}>
      <div>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorState}
          toolbar={toolbarOptions}
          placeholder="Введите комментарий"
        />
      </div>
      <Row
        gutter={[16, 16]}
      >
        <Col>
          <Button
            type="primary"
            disabled={isLoading}
            onClick={(): void => {
              onClickSave(getTextFromRaw(convertToRaw(editorState.getCurrentContent())))
            }}>
              Сохранить
          </Button>
        </Col>
        <Col>
          <Button onClick={onClickClose}>Отмена</Button>
        </Col>
      </Row>
    </Card>
  )
}