import { LogoutOutlined,UserOutlined } from "@ant-design/icons"
import { Avatar, Button, Col, Row } from "antd"
import React from "react"

import css from "./index.module.scss"

interface ProfileMenuViewProps {
  avatarUrl: string | null
  name: string
  onLogout: () => void
}

export function ProfileMenuView({
  avatarUrl,
  name,
  onLogout
}: ProfileMenuViewProps): React.ReactElement {
  return (
    <Row wrap>
      <Col>
        <Avatar size="large" shape="square" icon={<UserOutlined />} src={avatarUrl} />
      </Col>
      <Col offset={1}>
        <div className={css.profileMenuNameContainer}>
          <div>
            {name}
          </div>
        </div>
      </Col>
      <Col offset={1}>
        <div className={css.profileMenuNameContainer}>
          <div><Button type="primary" shape="circle" icon={<LogoutOutlined />} onClick={onLogout}/></div>
        </div>
      </Col>
    </Row>
  )
}