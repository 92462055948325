import { Spin } from "antd"
import { ResponseApi } from "api/response.type"
import { PAGE_SIZE } from "app/pages/text-reader.constant"
import useAxios from "axios-hooks"
import React, { useEffect } from "react"

import { Comment } from "../../../interfaces/comment.types"
import { CommentsListView } from "./comments-list.view"

interface CommentsListProps {
  title: string
  articleId: string
  current_page?: number
  doRefetch?: boolean
}

export function CommentsList({ articleId, title, current_page = 1, doRefetch = false }: CommentsListProps): React.ReactElement {
  const [{ data, loading }, refetch] = useAxios<ResponseApi<{ article_comments: Comment[] }>>({
    url: "/comment/get_comments_by_rows",
    params: {
      article_id: articleId,
      from_row: (current_page - 1) * PAGE_SIZE,
      num_rows: PAGE_SIZE
    }
  })

  useEffect(() => {
    if (doRefetch) {
      refetch().catch(console.error)
    }
    console.log(doRefetch)
  }, [doRefetch, refetch])

  if (loading || data == null) {
    return <Spin />
  }

  return <CommentsListView title={title} comments={data.result.article_comments} />
}

