import useAxios from "axios-hooks"
import React from "react"

import { SelectedWordInfo } from "../types"
import { CommentsEditorView } from "./comments-editor.view"

interface CommentsEditorProps {
  selectedWordInfo: SelectedWordInfo
  articleId: string
  onClickCancel: () => void
  onClickSave: () => void
}

export function CommentsAddEditor({
  selectedWordInfo,
  articleId,
  onClickCancel,
  onClickSave
}: CommentsEditorProps): React.ReactElement {
  const [{ loading }, execute] = useAxios({
    url: "/comment/add_comment",
    method: "POST",
  }, { manual: true })

  async function handleSaveComment(content: string): Promise<void> {
    await execute({
      data: {
        article_id: articleId,
        comment_start_index: selectedWordInfo.wordIndex,
        comment_end_index: selectedWordInfo.wordIndex,
        row_number_in_article: selectedWordInfo.rowNumber,
        content,
        author: "unknow"
      }
    })
    onClickSave()
  }

  return (
    <CommentsEditorView
      isLoading={loading}
      onClickSave={handleSaveComment}
      onClickClose={onClickCancel}
    />
  )
}
