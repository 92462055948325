import React from "react"

import { InfiniteProgressBar } from "../ui/progress-bar"
import css from "./index.module.scss"

export function RoutingIndicator(): React.ReactElement {
  return (<div className={css.wrapper}>
    <InfiniteProgressBar />
  </div>)
}
