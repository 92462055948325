import { Card, Empty, Spin } from "antd"
import { ResponseApi } from "api/response.type"
import { CommentCeil } from "app/components/comments/comment-ceil/comment-ceil.container"
import useAxios from "axios-hooks"
import _ from "lodash"
import React from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { Comment } from "../../interfaces/comment.types"
import { ContentBox } from "../layout/components/content-box/content-box.view"
import { PAGE_SIZE } from "../text-reader.constant"
import { TextReader } from "./components/text-reader/text-reader.container"

function CommentPage(): React.ReactElement {
  const param = useParams<{ articleId: string, commentId: string }>()
  const navigate = useNavigate()
  const articleId = param.articleId
  const commentId = param.commentId ?? ""
  
  const [comment_state] = useAxios<ResponseApi<{ article_comment: Comment }>>({
    url: "/comment/get_comment_by_id",
    params: {
      comment_id: commentId
    }
  })

  function handlePressPage(pageNumber: number): void {
    navigate({
      pathname: `/article/${articleId}`,
      search: `?page=${pageNumber}`,
    })
  }

  if (articleId == null) {
    return <Empty description="Произведение не найдено" />
  }

  if (comment_state.loading || comment_state.data == null)
    return <Spin />

  const rowNumber = comment_state.data.result.article_comment.row_number_in_article
  const currentPage = rowNumber - PAGE_SIZE <= 0 ? 1 : _.floor(rowNumber / PAGE_SIZE) + 1

  return (
    <ContentBox
      left={
        <TextReader
          articleId={articleId}
          commentId={commentId}
          currentPage={currentPage}
          setCurrentPage={handlePressPage}
        />
      }
      right={
        <Card title="Просмотр комментария" extra={<Link to={`/article/${articleId}?page=${currentPage}`}>Все комментарии</Link>}>
          <CommentCeil commentId={commentId} />
        </Card>
      }
    />
  )
}

export default CommentPage
