/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Badge, Card, Col, Pagination, Row, Space, Typography } from "antd"
import { ArticleRow } from "app/interfaces/article.types"
import { Comment } from "app/interfaces/comment.types"
import _ from "lodash"
import React, { useState } from "react"

import { PAGE_SIZE } from "../../../text-reader.constant"

const { Title, Text } = Typography

interface TextReaderViewProps {
  title: string
  author: string
  rows: ArticleRow[]
  comments: Comment[]
  commentId: string
  onClickWord: (wordIndex: number, rowNumber: number) => void
  currentPage: number
  total: number
  onChangeCurrentPage: (page: number) => void
  onClickCommentBadge: (commentId: string) => void
}

function WordReaderView(props: {
  word: string
  index: number
  mark?: boolean
  commentNumber: number
  onClickWord: () => void
}): React.ReactElement {
  const { word, onClickWord, commentNumber, mark = false } = props
  const [isHoverRow, setHoverRow] = useState<boolean>(false)
  return (
    <div
      onMouseEnter={(): void => setHoverRow(true)}
      onMouseLeave={(): void => setHoverRow(false)}
      style={{
        cursor: isHoverRow ? "pointer" : undefined
      }}
      onClick={onClickWord}
    >

      <Badge
        count={commentNumber}
        size="small"
        color="blue"
      >
        <Text
          style={{
            lineHeight: "1.1em",
            fontSize: 16
          }}
          mark={mark}
          underline={isHoverRow && commentNumber == 0}
        >
          {word}
        </Text>
      </Badge>
    </div>
  )
}

export function TextReaderView({
  title,
  author,
  rows,
  comments,
  commentId,
  onClickWord,
  currentPage,
  total,
  onChangeCurrentPage,
  onClickCommentBadge
}: TextReaderViewProps): React.ReactElement {
  return (
    <Card>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row justify="center">
          <Col><Title level={4} style={{ marginTop: 10 }} type="secondary">{author}</Title></Col>
        </Row>
        <Row justify="center">
          <Col><Title level={3} style={{ marginTop: 0 }}>{title}</Title></Col>
        </Row>
        <div>
          {rows.map((row, index) => {
            return (
              <Row key={index} justify="center">
                <Space size="small">
                  {
                    _.zip(row.row_content.split(" "), row.content_indexes).map(([word, wordIndex], index) => {
                      const commentNumber = comments.findIndex((comment) => wordIndex == comment.comment_end_index)
                      return (
                        <WordReaderView
                          word={word ?? ""}
                          index={wordIndex ?? -1}
                          key={index}
                          mark={commentId == comments[commentNumber]?.comment_id}
                          onClickWord={(): void => {
                            if (commentNumber == -1)
                              onClickWord(wordIndex ?? -1, (currentPage - 1) * PAGE_SIZE + index)
                            else
                              onClickCommentBadge(comments[commentNumber].comment_id)
                          }}
                          commentNumber={commentNumber + 1}
                        />
                      )
                    })
                  }
                </Space>
              </Row>)
          })}
        </div>
        <Row justify="center">
          <Col>
            <Pagination
              current={currentPage}
              onChange={onChangeCurrentPage}
              showSizeChanger={false}
              total={total}
              pageSize={PAGE_SIZE}
            />
          </Col>
        </Row>
      </Space>
    </Card>
  )
}
