import { Col, Row } from "antd"
import React from "react"

interface ContentBoxView {
  left?: React.ReactNode
  right?: React.ReactNode
}

export function ContentBox({
  left,
  right
}: ContentBoxView): React.ReactElement {
  return (
    <Row
      gutter={[16, 16]}
      style={{
        padding: "0px 32px",
        height: "750px"
      }}
    >
      <Col span={12} style={{ height: "750px" }}>
        {left}
      </Col>
      <Col span={12} style={{ height: "100%", overflowY: "scroll" }}>
        {right}
      </Col>
    </Row>
  )
}