import useAxios from "axios-hooks"

import { getAuthToken } from "."

interface User {
  username: string
  email: string
}

type UseAuthUserResponse = {
  user: User | null
  loading: boolean
}

export function useAuthUser(): UseAuthUserResponse {
  const accessToken = getAuthToken()
  const [{ data, loading }] = useAxios<User | undefined>({
    url: "/users/me",
    headers: {
      Authorization: accessToken
    }
  })

  return {
    user: data ?? null,
    loading
  }
}
