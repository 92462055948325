import { SearchOutlined } from "@ant-design/icons"
import { Empty, Select } from "antd"
import { ArticleRow } from "app/interfaces/article.types"
import { Comment } from "app/interfaces/comment.types"
import React from "react"

import css from "./search.module.scss"

interface SearchContainerViewProps {
  value: string | undefined
  loading: boolean
  onChangeValue: (value: string) => void
  onSearchValue: (value: string) => void
  articleRows: ArticleRow[]
  comments: Comment[]
}

export function SearchContainerView(props: SearchContainerViewProps): React.ReactElement {
  const { value, onChangeValue, onSearchValue, loading, articleRows, comments } = props

  const options = articleRows.length == 0 && comments.length == 0 ? [] : [
    {
      label: "Произведения",
      options: articleRows.map((row) => ({
        value: `p-|-${row.row_id}`,
        label: `${row.author}, ${row.title}: ${row.row_content}`
      }))
    },
    {
      label: "Комментарии",
      options: comments.map((comment) => ({
        value: `c-|-${comment.id}`,
        label: comment.content //<Text style={{ width: 200 }}>{comment.content}</Text>
      }))
    }
  ]

  return (
    <Select
      showSearch
      value={value}
      loading={loading}
      placeholder="Введите строку для поиска по статьям"
      className={css.searchContainer}
      defaultActiveFirstOption={false}
      suffixIcon={<SearchOutlined />}
      filterOption={false}
      onSearch={onSearchValue}
      onChange={onChangeValue}
      notFoundContent={<Empty description="Нет результатов по запросу" />}
      options={options}
    />
  )
}
