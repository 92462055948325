import { Empty, Spin } from "antd"
import { ResponseApi } from "api/response.type"
import { Article, ArticleRow } from "app/interfaces/article.types"
import { Comment } from "app/interfaces/comment.types"
import useAxios from "axios-hooks"
import React from "react"
import { useNavigate } from "react-router-dom"

import { PAGE_SIZE } from "../../../text-reader.constant"
import { TextReaderView } from "./text-reader.view"


interface TextReaderProps {
  articleId: string
  commentId: string
  currentPage: number
  setCurrentPage: (page: number) => void
}

export function TextReader({
  articleId,
  commentId,
  currentPage,
  setCurrentPage
}: TextReaderProps): React.ReactElement {
  const navigate = useNavigate()
  const [articleState] = useAxios<ResponseApi<{ article: Article }>>({
    url: "/article/get_article_by_id",
    params: {
      article_id: articleId
    }
  })

  const [rowsState] = useAxios<ResponseApi<{ article_rows: ArticleRow[] }>>({
    url: "/article/get_article_by_rows",
    params: {
      article_id: articleId,
      from_row: (currentPage - 1) * PAGE_SIZE,
      num_rows: PAGE_SIZE
    }
  })

  const [commentsState] = useAxios<ResponseApi<{ article_comments: Comment[] }>>({
    url: "/comment/get_comments_by_rows",
    params: {
      article_id: articleId,
      from_row: (currentPage - 1) * PAGE_SIZE,
      num_rows: PAGE_SIZE
    }
  })

  const [totalRowsState] = useAxios<ResponseApi<{ article_rows: ArticleRow[] }>>({
    url: "/article/get_article_by_rows",
    params: {
      article_id: articleId,
      from_row: 0,
      num_rows: 0
    }
  })

  function handleClickCommentBadge(commentId: string): void {
    navigate(
      {
        pathname: `/article/${articleId}/comment/${commentId}`,
        search: `?page=${currentPage}`,
      }
    )
  }

  function handleClickWord(wordIndex: number, rowNumber: number): void {
    navigate(
      {
        pathname: `/article/${articleId}`,
        search: `?page=${currentPage}&wordIndex=${wordIndex}&rowNumber=${rowNumber}`,
      }
    )
  }

  if (articleState.loading || commentsState.loading)
    return <Spin />
  
  if (articleState.data == null || totalRowsState.data == null)
    return <Spin />

  if (articleState.data.status == "error") {
    return <Empty description="Нет информации по статье" />
  }

  const article = articleState.data.result.article
  return (
    <TextReaderView
      title={article.title}
      author={article.author}
      rows={rowsState.data?.result.article_rows ?? []}
      comments={commentsState.data?.result.article_comments ?? []}
      commentId={commentId}
      onClickWord={handleClickWord}
      currentPage={currentPage}
      onChangeCurrentPage={setCurrentPage}
      onClickCommentBadge={handleClickCommentBadge}
      total={Math.floor(totalRowsState.data.result.article_rows.length)}
    />
  )
}
