import { Spin } from "antd"
import { ResponseApi } from "api/response.type"
import useAxios from "axios-hooks"
import React from "react"

import { Comment } from "../../../../interfaces/comment.types"
import { CommentsEditorView } from "./comments-editor.view"

interface CommentsEditorProps {
  commentId: string
  onClickCancel: () => void
  onClickSave: () => void
}

export function CommentsUpdateEditor({
  commentId,
  onClickCancel,
  onClickSave
}: CommentsEditorProps): React.ReactElement {
  const [comment_state] = useAxios<ResponseApi<{ article_comment: Comment }>>({
    url: "/comment/get_comment_by_id",
    params: {
      comment_id: commentId
    }
  })
  
  const [{ loading }, execute] = useAxios({
    url: "/comment/edit_comment",
    method: "POST",
  }, {
    manual: true
  })

  async function handleSaveComment(content: string): Promise<void> {
    await execute({
      data: {
        comment_id: commentId,
        comment_text: content,
        comment_html: ""
      }
    })
    onClickSave()
  }

  if (comment_state.loading || comment_state.data == null) {
    return <Spin />
  }

  return (
    <CommentsEditorView
      initialText={comment_state.data.result.article_comment.content}
      isLoading={loading}
      onClickSave={handleSaveComment}
      onClickClose={onClickCancel}
    />
  )
}
